<template>
  <EmailEditor
    :appearance="appearance"
    :min-height="minHeight"
    :project-id="projectId"
    :locale="locale"
    :tools="tools"
    :options="options"
    ref="emailEditor"
    v-on:load="loadDesign"
  />
</template>

<script>
import { EmailEditor } from "vue-email-editor";

export default {
  props: {
    design: {
      type: Array,
    },
    background: {
      type: String,
    }    
  },
  components: {
    EmailEditor,
  },
  data() {
    return {
      minHeight: "500px",
      locale: "pt-BR",
      projectId: 30213, // replace with your project id
      tools: {
        // disable image tool
        image: {
          enabled: true,
        },
      },
      options: {},
      appearance: {
        //   theme: 'dark',
        panels: {
          tools: {
            dock: "left",
          },
        },
      },
      designInit: {
        body: {
          rows: [
            {
              cells: [],
              columns: [
                {
                  contents: [],
                  values: {
                    backgroundColor: "",
                    padding: "0px",
                    border: {},
                    _meta: {
                      htmlID: "u_column_1",
                      htmlClassNames: "u_column",
                    },
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false,
                },
                padding: "0px",
                hideDesktop: false,
                _meta: {
                  htmlID: "u_row_1",
                  htmlClassNames: "u_row",
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            },
          ],
          values: {
            textColor: "#000000",
            backgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: true,
            },
            contentWidth: "760px",
            contentAlign: "center",
            fontFamily: {
              label: "Arial",
              value: "arial,helvetica,sans-serif",
            },
            preheaderText: "",
            linkStyle: {
              body: true,
              linkColor: "#0000ee",
              linkHoverColor: "#0000ee",
              linkUnderline: true,
              linkHoverUnderline: true,
            },
            _meta: {
              htmlID: "u_body",
              htmlClassNames: "u_body",
            },
          },
        },
        schemaVersion: 6,
      },
      template: {
        design: "",
        html: "",
      },
    };
  },
  watch: {
    background: {
      async handler(bgId) {
        const n = _.filter(this.$store.state.global.backgroundsList, (bg) => {
          return bg.id == bgId;
        });

        let temp = await this.getDesign();

        this.designInit.body.values.backgroundImage.url = n[0].url;

        this.designInit.body.rows = temp;

        this.$refs.emailEditor.editor.loadDesign(this.designInit);
      },
    },
    design(n) {
      console.log('design load', n)
      this.loadDesign();
    },
  },
  methods: {
    loadDesign() {
      const self = this;
      return new Promise(function (res, rej) {
        if (self.design && self.design.length > 0) {
          self.designInit.body.rows = self.design;
        }

        self.$refs.emailEditor.editor.loadDesign(self.designInit);

        self.$refs.emailEditor.editor.addEventListener("design:loaded", () => {

          self.$refs.emailEditor.editor.exportHtml((data) => {
            let { html } = data;

            if (self.background) {
              let el = `<style type="text/css"> .clean-body{background-image:url("${self.background_url()}");background-size:cover;background-position:top;background-repeat: no-repeat;}`;

              html = html.replace('<style type="text/css">', el);
            }

            res(html);
          });
        });
      });
    },
    getDesign() {
      return new Promise((res, rej) => {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          res(design.body.rows);
        });
      });
    },
    getHtml() {
      return new Promise((res, rej) => {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          let { html } = data;

          if (this.background) {
            let el = `<style type="text/css"> .clean-body{background-image:url("${this.background_url()}");background-size:cover;background-position:center;}`;

            html = html.replace('<style type="text/css">', el);
          }
          res(html);
        });
      });
    },
    async save_design() {
      let design = await this.getDesign();

      let html = await this.getHtml();

      return { design, html };
    },
    background_url() {
      const n = _.filter(this.$store.state.global.backgroundsList, (bg) => {
        return bg.id == this.background;
      });
      return n[0].url;
    },
  },
  mounted() {
    if (this.background) {
      const n = _.filter(this.$store.state.global.backgroundsList, (bg) => {
        return bg.id == this.background;
      });
      this.designInit.body.values.backgroundImage.url = n[0].url;
    }
  },
};
</script>
<style scoped>
.unlayer-editor {
  height: 60vh;
}
</style>